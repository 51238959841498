.App {
  text-align: center;
  overflow: hidden;
  direction: rtl;
  height: 90vh;
  width: 100vw;
}

.cat {
  transform: scale(1, 1) translate(0px, -31px);
  margin: 0 auto;
}

.swipGrid {
  max-width: 325px;
  display: grid;
  grid-template-columns: 30px auto;
  gap: 2px;


}

.flach {
  display: grid;
  transform: translate(0px, 11.75%);
  grid-template-columns: 1fr;
  place-content: center;
  place-items: center;
  background-color: #7332aa;
  border-radius: 24px;
  padding: 0px 10px 0px 10px;
  margin: 12px;
  color: white;
  box-shadow: 0px 0px 10px 0px #ccc;
}

from {
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

to {
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -ms-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  75% {
    -ms-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);

  }

  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.swipImg {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 4s linear infinite;
  width: 30px
}


.owl-carousel,
.bx-wrapper {
  direction: ltr;
}

.owl-carousel .owl-item {
  direction: rtl;
}

.MobileLogo {
  display: block;
  margin: 18px auto;
  width: 6rem;
}

.MoblieWaveLogo {
  margin-top: 0rem;
  width: 6rem;
}

.owl-item {}

@media only screen and (min-width: 1400px) {
  .hide {
    display: none;
  }

  .App {
    text-align: center;
    overflow: hidden;
    direction: rtl;
    height: 100%;
    width: 100vw;
  }
}

@media only screen and (max-width: 900px) {
  .App {
    
    max-width: 100vw;
    max-height: 100vh;
  }

  .cat {

    transform: scale(0.85, 0.85) translate(0px, -5vh);
    margin: 0 auto;
  }


  .MoblieWaveLogo {
    position: absolute;
    bottom: 0;
    width: 4rem;
    margin: 10px auto;
  }

  .MobileLogo {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .owl-prev {
    display: none !important;
  }

  .App {
    overflow: hidden;
  }

  .MoblieWaveLogo {
    display: block;

    position: absolute;
    bottom: 0;
    width: 4rem;
    left: 44.444%;
  }


  .Contain {
    width: 100% !important;
    overflow: hidden;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
  }

  .MobileLogo {
    display: block;
    margin: 0 auto;
    margin-top: 10px;
  }
}

.container {
  max-width: 1600px !important;
  max-height: auto !important;
  margin-top: 3%;


}

.Contain {
  max-width: 85%;
  margin: auto;
  margin-top: 2%;

  position: relative;
  height: auto;
}

.owl-carousel.owl-loaded {
  border: 1px solid #dddddd !important;


}

.owl-carousel {}


.owl-prev,
.owl-next {
  width: 40px;
  height: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block !important;
  border: 0px solid black;
}

.owl-prev {
  right: -45px;
}

.owl-next {
  left: -45px;
}

.owl-prev i,
.owl-next i {
  transform: scale(2, 5);
  color: #ccc;
}

.owl-nav {}

.owl-dots {
  display: none;


}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  direction: ltr;
}

.plusSVG {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 20px !important;
  width: 20px !important;
  cursor: pointer;
}

.item {
  position: relative;
}

.HotSpotAddisional {
  background-color: #ffffff00;
  position: absolute;

}

.HotSpotAddisional:hover {
  background-color: #50505061;

}

.HotSpotAbs {
  position: absolute;
}

.previous {
  /*   position: absolute;
  left: -65px;
  top:40%;

  z-index: 10000;
  width: 5% !important;
  background-color: #ffffff00;
  height: auto;
  cursor: pointer; */

}

.NextAsid {
  /*   position: absolute;
  right: -65px;
  top:40%;
  cursor: pointer;
  z-index: 10000;
  width: 5% !important;
  background-color: #ffffff00;
  height: auto; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {}

.App-link {
  color: #61dafb;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}